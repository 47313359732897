PortalService = function() {
};

PortalService.prototype.ajaxConfig = function() {
	return {
		url: '/services/user/portals',
		dataType: 'json',
		headers: {
			'Authorization': 'Bearer ' + App._api_token
		}
	}
};

PortalService.prototype.list = function() {
	return new Promise(function(resolve, reject) {
		if (App.PortalCollection.length) {
			resolve();
			return;
		}
		setTimeout(reject, 30E3);
		$(window).on('fa:portals-resolved', resolve);
	});
};

PortalService.prototype.find = function() {
	return new Promise(function(resolve, reject) {
		resolve();
	});
};

PortalService.instance = null;
PortalService.getInstance = function() {
	return PortalService.instance || (PortalService.instance = new PortalService());
};
