VideoService = function() {
};

VideoService.prototype.ajaxConfig = function() {
	var cfg = {
		url: '/services/video/',
		dataType: 'json',
		headers: {
			'Authorization': 'Bearer ' + App._api_token
		}
	};
	$.ajaxSetup(cfg);
	return cfg;
};

VideoService.prototype.find = function() {
	return new Promise(function(resolve, reject) {
		resolve();
	});
};

VideoService.prototype.findByGameAndType = function(game, type) {
	var aj = this.ajaxConfig();
	var gameCentralId = game.origin.id;
	return new Promise(function(resolve, reject) {
		$.get(aj.url + 'videos-by-game/' + gameCentralId).always(resolve);
	});
};

VideoService.prototype.findAll = function() {
	var aj = this.ajaxConfig();
	return new Promise(function(resolve, reject) {
		$.get(aj.url + 'videos').always(resolve);
	});
};

VideoService.prototype.insert = function(data) {
	var aj = this.ajaxConfig();
	return new Promise(function(resolve, reject) {
		$.post(aj.url + 'videos/', data).always(resolve);
	});
};

VideoService.prototype.update = function(id, data) {
	if (!id) {
		return this.insert(data);
	}
	var aj = this.ajaxConfig();
	return new Promise(function(resolve, reject) {
		$.ajax({'method': 'PUT', 'url': aj.url + 'videos/' + id, 'data': data}).always(resolve);
	});
};

VideoService.instance = null;
VideoService.getInstance = function() {
	return VideoService.instance || (VideoService.instance = new VideoService());
};
