Video = function() {
	this.service = VideoService.getInstance();
	this.import({});
};

Video.prototype.import = function(data) {
	if (typeof data !== "undefined") {
		if (typeof data.params === "string") {
			data.params = JSON.parse(data.params);
		}
	}
	this.data = $.extend({
		"id": "",
		"game_id": "",
		"params": {
			"content_url": ""
		},
		"title": "",
		"type": "walkthrough"
	}, data);
	this.data.baseUrl = "//play." + getEnv() + "famobi.com/";
	return this;
};
Video.prototype.export = function() {
	return this.data;
};

Video.prototype.getId = function() {
	return this.data.id;
};

Video.prototype.getFilename = function() {
	var filename = this.data.params ? this.data.params.content_url : "";
	
	return filename;
};

Video.prototype.setTitle = function(title) {
	$.extend(this.data, {
		"title": title
	});
};

Video.prototype.getTitle = function() {
	return this.title;
};

Video.prototype.setFilename = function(filename) {
	$.extend(this.data, {
		"params": {
			"content_url": filename
		}
	});
};

Video.prototype.setGame = function(Game) {
	this.game = Game;
	this.data.game_id = Game.origin.id;
	this.setTitle(this.game.name + " Walkthrough");
};

Video.prototype.update = function(data) {
	$.extend(this.data, data);

	data = this.export();

	// Serialize params field
	data.params = JSON.stringify(data.params);

	return this.service.update(this.getId(), data);
};


