function fg_sdk() {
	this.ready = false;
	this.storage = null;
	this.aid = null;
	this.package_id = null;
	return this;
}

fg_sdk.prototype.init = function() {

	if (window.console) {
		console.log("[famobi sdk] init...");
	}
	
	this.storage = this.localStorage('localStorage');
	this.listen();
	this.ready = true;
	return this;
};

fg_sdk.prototype.listen = function() {

	var self = this;

	if (window.console) {
		console.log("[famobi sdk] listen...");
	}

	window.addEventListener('message', function(event) {

		var request = event.data,
        data;

		// check if i have to care about...
		if(request.scope != 'famobi_sdk') return; // nope

		if(typeof request.aid !== "undefined") this.aid = request.aid;
		if(typeof request.package_id !== "undefined") this.package_id = request.package_id;

		switch (request.method) {
			case 'getData':
				// game.setWaiting(true);
				event.source.postMessage({module: 'sdk', action: 'game.setWaiting', data: {setWaiting: true}}, '*');

				data = self.storage.getData(request.aid, request.package_id);
				// alert(+(new Date) - request.time);
				event.source.postMessage({module: 'sdk', action: 'setItems', data: data}, '*');
				break;
			case 'setItem':
				self.storage.setItem(request.data.key, request.data.value, '');
				break;
			case 'removeItem':
				self.storage.removeItem(request.data.key, '');
				break;
			default:
				// do nothing, be happy
		}

	}, false);
};

fg_sdk.prototype.localStorage = function(type) {
	var self = this,
		postMessageListeners = {},
		M;

	function module() { // define private vars

	}

	var storagePrototype = module.prototype;

	storagePrototype.init = function(storageType) {
		this.localStorage = {};
		this.sessionStorage = {};
		this.storage = {}; // one of the above

		if (storageType == 'localStorage' ||
			storageType == 'sessionStorage') {
				M.setStorageType(storageType);
				M.createStorage(storageType);
		}
	};

	storagePrototype.setStorageType = function(storageType) {
		M.storageType = storageType;
	};

	storagePrototype.getStorageType = function() {
		return M.storageType;
	};

	storagePrototype.createStorage = function(storageType) {
		if (testStorage(storageType)) {
			M.storage = createProxyStorage(storageType);
		} else {
			M.storage = createFallbackStorage(storageType);
		}
	};

	function testStorage(storageType) {
		if (!window[storageType]) {
			return false;
		}
		var storage = window[storageType];
		try {
			storage.setItem('test', 1);
			storage.removeItem('test');
			return true;
		} catch (e) {
			return false;
		}
	}

	function getNamespacedKey(key, ns) {
		if (typeof ns === "undefined") {
			ns = this.aid ? this.aid : this.package_id ? this.package_id : ''; // formerly: ns = famobi_gameID
		}
		if (ns.length) {
			key = ns + ':' + key;
		}
		return key;
	}

	// Create a storage proxy (same api like local-/sessionStorage) with double the data!
	function createProxyStorage(storageType) {
		return {
			data: {},
			getItem: function (key, ns) {
				key = getNamespacedKey(key, ns);
				return window[storageType].getItem(key);
			},
			getProxyItem: function (key, ns) {
				key = getNamespacedKey(key, ns);
				return this.data[key];
			},
			setItem: function (key, value, ns) {
				key = getNamespacedKey(key, ns);
				this.data[key] = value;
				window[storageType].setItem(key, value);
			},
			removeItem: function (key, ns) {
				key = getNamespacedKey(key, ns);
				try {
					delete this.data[key];
				} catch (e) {
				}
				window[storageType].removeItem(key);
			},
			clear: function () {
				this.data = {};
				window[storageType].clear();
			}
		};
	}

	// Create a storage stub (same api like local-/sessionStorage)
	function createFallbackStorage(storageType) {
		var key = '';
		return {
			data: {},
			getItem: function (key, ns) {
				key = getNamespacedKey(key, ns);
				return this.data[key];
			},
			setItem: function (key, value, ns) {
				key = getNamespacedKey(key, ns);
				this.data[key] = value;
			},
			removeItem: function (key, ns) {
				key = getNamespacedKey(key, ns);
				try {
					delete this.data[key];
				} catch (e) {
				}
			},
			clear: function () {
				this.data = {};
			}
		};
	}

	storagePrototype.getData = function(aid, package_id) {

		var keys = [],
			data = {},
			aid_check = false,
			package_id_check = true,
      key = '';
			value = null;

		if(typeof localStorage !== "undefined") {
			for (key in localStorage) {
				keys.push(key);
			}
		}

		if(keys.length > 0) {
			if(typeof aid !== "undefined" && aid.length > 0) aid_check = true;
			if(typeof package_id !== "undefined" && package_id.length > 0) package_id_check = true;

			for (key in keys) {
				value = keys[key];
				if((aid_check && keys[key].indexOf(aid) === 0) || (package_id_check && keys[key].indexOf(package_id) === 0)) data[keys[key]] = localStorage.getItem(keys[key]);
			}
		}
		return data;
	};

	storagePrototype.getStorage = function() {
		return M.storage;
	};

	storagePrototype.getItem = function(key, ns) {
		var value = M.getStorage().getItem(key, ns);
		return value;
	};

	storagePrototype.setItem = function(key, value, ns) {
		return M.getStorage().setItem(key, value, ns);
	};

	storagePrototype.setMaxItem = function(key, value, ns) {
		var old_value = parseInt(M.getStorage().getItem(key, ns), 10);

		if(!old_value || parseInt(value, 10) > old_value) {
			M.getStorage().setItem(key, value, ns);
			return true;
		} else {
			return false;
		}
	};

	storagePrototype.removeItem = function(key, ns) {
		return M.getStorage().removeItem(key, ns);
	};

	storagePrototype.clear = function() {
		return M.getStorage().clear();
	};

	M = new module();
	M.init(type);

	return M;
};
  
fg_sdk.prototype.embedFrame = function(fg_frame, fg_url, mobileRedirect, mobileTablet) {
	var detection=this.detection;
	var isPc=detection.is.pc;
	var isTab=detection.is.tablet;
	var isMob=detection.is.smartphone;
	if(isTab&&mobileTablet){
		isMob=!0;
	}
	if(isMob&&mobileRedirect){
		setTimeout(function(){
			document.location.href=fg_url;
		},100);
	}else{
		if(isMob){
			fg_frame.width=window.innerWidth;
			fg_frame.height=window.innerHeight;
		}
        fg_frame.style.width = parseInt(fg_frame.width) + "px";
        fg_frame.style.height = parseInt(fg_frame.height) + "px";
        fg_frame.setAttribute('allowFullscreen', 'true');
		fg_frame.src=fg_url;
	}
};

fg_sdk.prototype.detection = (function() {
	var mod = {is:{}},
		d,
		ua = navigator.userAgent;
	mod.detect = {
		html5: function() {
			return document.createElement('canvas').getContext !== undefined;
		},
		touch: function() {
			var supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;
			return !!supportsTouch;
		},
		android: function() {
			return !!ua.match(/Android/i);
		},
		ios: function() {
			return !!ua.match(/iPhone|iPad|iPod/i);
		},
		ios7: function(){
			return mod.detect.ios && ua.match(/version\/7\./i);
		},
		bb10: function() {
			return !!ua.match(/BB10/i);
		},
		windows: function() {
			return !!ua.match(/Windows/i);
		},
		webkitVersion: function() {
			var regex = new RegExp(/AppleWebKit\/([\d.]+)/),
				result = regex.exec(ua),
				webkitVersion = result === null ? false : parseFloat(result[1]);
			return webkitVersion;
		},
		androidStockBrowser: function() {
			if (mod.is.android && mod.is.webkitVersion && mod.is.webkitVersion < 537) {
				return true;
			}
			return false;
		},
		standalone: function() {
			return !!window.navigator.standalone;
		},
		smartphone: function() {
			return (ua.match(/Android.*Mobile|iPhone|IEMobile|WPDesktop|BB10/i)) ? true : false;
		},
		tablet: function() {
			if(window.self != window.top)
				return false;
			// Android smartphones have the combination Android...Mobile, tablets only Android
			var androidTablet = (mod.is.android && !mod.is.smartphone),
				iPad = ua.match(/iPad/i) ? true : false;
			return (androidTablet || iPad);
		},
		pc: function() {
			return (!mod.is.smartphone && !mod.is.tablet);
		},
		phantom: function() {
			return !!(window.callPhantom || ua.match(/PhantomJS/));
		},
		iframe: function(){
			return window.self != window.top;
		}
	};

	for (d in mod.detect) {
		if (typeof mod.detect[d] === 'function') {
			mod.is[d] = mod.detect[d]();
		}
	}

	return mod;
})();

window.famobi_sdk = new fg_sdk();
window.famobi_sdk.init();