VideoPlayerModule = function() {
	this.video = new Video();
	this.context = {
		"urlPrefix": "https://video.cdn.famobi.com/marketing/videos",
		"videoUrl": "",
		"video": this.video
	};
};

VideoPlayerModule.prototype.registerPartial = function () {

	Handlebars.registerPartial('videoUrl',
		'<p style="font-family: Courier New, monospace; font-size: 1em;">{{ urlPrefix }} /&nbsp;<i class="fa fa-film"></i> <textarea placeholder="Insert .mp4 file name" rows="1" cols="30" maxlength="40" style="border: none; border-bottom: 1px dashed #ddd; padding: 0.1em; color: #333; margin: 0; height: 1em; font-size: 1em; line-height: 1em; display: inline-block; margin-bottom: -3px;"></textarea>&nbsp;<button title="Preview"><i class="fa fa-eye fa-lg"></i></button>&nbsp;<button title="Save"><i class="fa fa-floppy-o fa-lg"></i></button></p>'.trim()
	);

	Handlebars.registerPartial('videoPlayer',
		'{{#if showVideo}}<h4 class="head">{{game.name}} Preview</h4><iframe style="margin-bottom: 20px;" src="{{video.baseUrl}}{{game.package_id}}/{{affiliate_id}}/video{{#if previewMode}}?fg_media_mp4={{video.params.content_url}}{{/if}}" height="320" width="100%" frameborder="0" framemargin="0">{{else}}{{#if admin}}<p><i class="fa fa-video-camera fa-lg"></i> This Video hasn\'t been captured or uploaded yet.{{/if}}{{/if}}'.trim()
	);
};

VideoPlayerModule.prototype.init = function(game) {
	var self = this;
	self.game = game;
	self.$elm = $('.videoPlayer .editor');
	self.editorSelector = "textarea";
	self.previewBtnSelector = 'button[title="Preview"]';
	self.saveBtnSelector = 'button[title="Save"]';
	self.registerPartial();

	Promise.all([
		PortalService.getInstance().list(),
		VideoService.getInstance().findByGameAndType(self.game, "walkthrough")
	]).then(function(values) {
		var video = values[1]["data"] ? values[1]["data"][0] : {};
		if (App.User.is_logged_in && App.User.can("admin")) {
			self.video = self.video.import(video);
			self.video.setGame(self.game);
			self.renderVideoUrl();

			self.bindEvents();
		} else {
			self.video = self.video.import(video);
			self.renderVideoPlayer();
		}
	});
};

VideoPlayerModule.prototype.bindEvents = function() {
	var self = this;

	$(self.$elm).on("pointerdown", self.previewBtnSelector, function() {
		console.log("PREVIEW");
		self.updateVideoUrl($(self.editorSelector).val());
		self.renderVideoUrl(true);
	});
	$(self.$elm).on("pointerdown", self.saveBtnSelector, function() {
		console.log("SAVE");
		self.updateVideoUrl($(self.editorSelector).val());
		self.saveVideo();
	});
};

VideoPlayerModule.prototype.updateVideoUrl = function(filename) {
	var self = this;

	self.video.setFilename(filename);
};

VideoPlayerModule.prototype.saveVideo = function() {
	var self = this;

	self.video.update();
};

VideoPlayerModule.prototype.renderVideoUrl = function(previewMode) {
	var self = this;
	var template = Handlebars.compile("{{> videoUrl }} {{> videoPlayer}}");
	var context  = $.extend(self.context, {
		"user": App.User,
		"admin": App.User.can("admin"),
		"portal": AffiliateWidget.selectedPortal,
		"game": self.game,
		"config": App.config,
		"video": self.video.export(),
		"previewMode": !!previewMode,
		"affiliate_id": AffiliateWidget.selectedPortal.affiliate_id || "A1000-1"
	});

	context.showVideo = self.video.getFilename();

	html = template(context);

	self.$elm.html(html);
	self.$elm.find(self.editorSelector).val(self.video.getFilename());
};

VideoPlayerModule.prototype.renderVideoPlayer = function() {
	var self = this;
	var template = Handlebars.compile("{{> videoPlayer}}");
	var context  = $.extend(self.context, {
		"user": App.User,
		"portal": AffiliateWidget.selectedPortal,
		"game": self.game,
		"config": App.config,
		"video": self.video.export(),
		"affiliate_id": AffiliateWidget.selectedPortal.affiliate_id || "A1000-1"
	});

	context.showVideo = self.video.getFilename();

	html = template(context);

	self.$elm.html(html);
};

VideoPlayer = new VideoPlayerModule();
