AffiliateWidget = new function() {

	this.portals = [];
	this.selectedPortal = famobi_sdk.storage.getItem('selectedPortal', 'AffiliateWidget') || '{}';
	try { 
		this.selectedPortal = JSON.parse(this.selectedPortal); 
	} catch (ex) { 
		this.selectedPortal = {}; 
	}
	this.game = {};

	this.frameWidth = Number(famobi_sdk.storage.getItem('frameWidth', 'AffiliateWidget')) || 640;
};

AffiliateWidget.registerPartial = function () {

	Handlebars.registerPartial('iframeHtml',
		'<iframe id="fg-frame-{{ game.package_id}}" width="{{frameWidth}}" height="{{frameHeight}}" data-aspect-ratio="{{ game.params.aspectRatio }}" src="" frameborder="0"></iframe>' +

		"<script>(function(d, url, fgJS, firstJS){fgJS = d.createElement('script');firstJS=d.getElementsByTagName('script')[0];fgJS.src=url;fgJS.onload=function() {" +
			"var fg_frame=document.getElementById('fg-frame-{{ game.package_id}}');" +
			"var fg_url='{{ game.play_url}}/{{portal.affiliate_id}}';" +
			"var mobileRedirect={{mobileRedirect}};" +
			"var mobileTablet={{mobileTablet}};" +
			"famobi_embedFrame(fg_frame, fg_url, mobileRedirect, mobileTablet);};" +
			"firstJS.parentNode.insertBefore(fgJS, firstJS);})" +
			"(document, 'https://games.cdn.famobi.com/html5games/plugins/embed.js?v=2.1')" +
		"</script>".trim()
	);

	Handlebars.registerPartial('affiliateLink',
	'<li>' +
		'<div class="inner">' +
			'<fieldset>' +
				'<label>{{#if user.is_logged_in}}Affiliate Link{{else}}Link{{/if}}</label>' +
				'<textarea class="url" readonly rows="1" data-embed-url>{{ game.play_url }}{{#if portal.affiliate_id}}/{{portal.affiliate_id}}{{/if}}</textarea>' +
			'</fieldset>' +
			'{{#if user.is_logged_in}}' +
			'{{#if embed_iframe}}' +
			'<fieldset>' +
				'<label>iFrame Embed Code</label>' +
				'<textarea rows="1" readonly data-embed-frame>{{> iframeHtml }}</textarea>' +
			'</fieldset>' +
			'{{/if}}' +
			'{{/if}}' +
		'</div>' +
	'</li>'
	);


	Handlebars.registerPartial('registerHint',
	'<li class="register">' +
		'<div class="inner">' +
			'<fieldset>' +
				'<label>Affiliate Link</label>' +
				'<div class="registerHint">' +
					'<a href="//{{ config.login_host }}{{ config.login_path }}?" class="button">Login or Register now</a>' +
					'<p>Register at Famobi for free to get your own Affiliate Link!</p>' +
				'</div>' +
			'</fieldset>' +
		'</div>' +
	'</li>'
	);

	Handlebars.registerPartial('settings',
	'{{#if embed_iframe}}' +
	'<li class="iframeSettings">' +
		'<div class="inner">' +
			'<fieldset>' +
				'<label>iFrame width (px)</label>' +
				'<input type="number" min="0" value="{{ frameWidth }}" step="5" data-controller="frameWidth">' +
			'</fieldset>' +
			'<fieldset>' +
				'<label title="iframe Settings"> </label>' +
				'<label><input type="checkbox" data-controller="mobileRedirect" {{#if mobileRedirect}}checked="checked"{{/if}} >' +
				'Open game on mobile via redirect for better performance</label>'+
				'<label><input type="checkbox" data-controller="mobileTablet" {{#if mobileTablet}}checked="checked"{{/if}}>' +
				'Handle tablets like mobile devices, not like laptops</label>' +
			'</fieldset>' +
		'</div>' +
	'</li>' +
	'{{/if}}'
	);
};

AffiliateWidget.ajaxConfig = function() {
	return {
		url: '/services/user/portals',
		dataType: 'json',
		delay: 500,
		cache: true,

		headers: {
			'Authorization': 'Bearer ' + App._api_token
		},

		processResults: function (response, params) {
			params.page =  params.page || 1;
			var term = params.term;

			return {
				results: $.map(response.data, function(portal) {
					if (term && (
						portal.name.toLowerCase().indexOf(term.toLowerCase()) !== -1 ||
						portal.affiliate_id.toLowerCase().indexOf(term.toLowerCase()) !== -1)
						) {
						return true;
					}

					return {
						id: portal.affiliate_id,
						text: portal.name
					}
				})
			};
		}
	}
};

AffiliateWidget.init = function(game) {
	AffiliateWidget.registerPartial();

	var self = this;
	self.game = game;
	self.$elm = $('.fa-portals').hide();

	App.getPortals();

	$(window).on('fa:portals-resolved', function()  {
		self.portals = $.map(App.PortalCollection, function (obj) {
			obj.id   = obj.affiliate_id;
			obj.text = obj.name;

			return obj;
		});

		if (App.User.is_logged_in) {
 			if (!self.selectedPortal.name && self.portals[0] && self.portals[0].name) {
				self.selectedPortal = self.portals[0];
 			}

			self.$elm = self.$elm.select2({
				placeholder: (self.selectedPortal && self.selectedPortal.name) ? self.selectedPortal.name + ' (' + self.selectedPortal.affiliate_id + ')' : 'Select a website',
				ajaxConfig: self.ajaxConfig,
				data: self.portals,
				allowClear: false,
				minimumInputLength: 0
			});

			self.$elm.val(self.selectedPortal.affiliate_id).trigger("change");
		}

		self.bindEvents();

		self.renderAffiliateLink();
	});
};

AffiliateWidget.bindEvents = function() {
	var self = this;
	self.$elm.on("select2:select", function(e) {
		self.selectedPortal = {
			name: e.params.data.text,
			affiliate_id: e.params.data.id
		};
		famobi_sdk.storage.setItem('selectedPortal', JSON.stringify(self.selectedPortal), 'AffiliateWidget');
		self.renderAffiliateLink();
	});

	App.handleClick('textarea[data-embed-url],textarea[data-embed-frame]', function() {
		this.focus();
		this.select();
		try {
			document.execCommand('copy');
			$('.clipboardHint').remove();
			$(this).after('<span class="clipboardHint">Copied to clipboard!</span>');
		} catch(e) {

		}

	});
};


AffiliateWidget.renderAffiliateLink = function() {

	var self = this;

	var portal  = self.selectedPortal;
	var template = Handlebars.compile('<ul>{{> affiliateLink}}  {{#if user.is_logged_in}} {{> settings }} {{else}} {{> registerHint }} {{/if}}</ul>');
	var context  = {
			"user": App.User,
			"portal": portal,
			"game": self.game,
			"config": App.config,
			"frameWidth": Math.round(Math.max(1,self.frameWidth)),
			"frameHeight": Math.max(1,self.game.params.aspectRatio > 0 ?
				Math.round(self.frameWidth / self.game.params.aspectRatio) : 0),
			"mobileRedirect": self.mobileRedirect || false,
			"mobileTablet": self.mobileTablet || false,
			"embed_iframe": self.game.package_id.indexOf(".app") === -1
		};

		html = template(context);

	$('.embedCode ul').replaceWith(html);

	$('[data-controller="mobileRedirect"]').change(function(e) {
		AffiliateWidget.mobileRedirect = AffiliateWidget.mobileRedirect ? false : true;
		famobi_sdk.storage.setItem('mobileRedirect', AffiliateWidget.mobileRedirect ? 1 : 0, 'AffiliateWidget');
		self.renderAffiliateLink();
	});

	$('[data-controller="mobileTablet"]').change(function(e) {
		AffiliateWidget.mobileTablet = AffiliateWidget.mobileTablet ? false : true;
		famobi_sdk.storage.setItem('mobileTablet', AffiliateWidget.mobileTablet ? 1 : 0, 'AffiliateWidget');
		self.renderAffiliateLink();
	});

	$('[data-controller="frameWidth"]').change(function(e) {
		AffiliateWidget.frameWidth = $(this).val();
		famobi_sdk.storage.setItem('frameWidth', AffiliateWidget.frameWidth, 'AffiliateWidget');
		self.renderAffiliateLink();
	});

};